import React from 'react';
import { Text } from '@humancollective/uikit-web';
import firebase from 'firebase/app';
import 'firebase/auth';

import CreateUserForm from '../Forms/CreateUser';
import Step1Image from '../../assets/images/register/step1.png';
import { RegisterStepComponentProps } from './';
import {
  StepContent,
  StepLeftContainer,
  StepLeftImageContent,
  StepRightContainer,
} from './display';
import Loader from '../Loader';

export const CreateAccountRegisterStep: React.FunctionComponent<
  RegisterStepComponentProps
> = ({ nextStep }) => {
  const [loading, setLoading] = React.useState<boolean>(true);

  const logoutIfLoggedIn = async () => {
    if (firebase.auth().currentUser) nextStep();
    setLoading(false);
  };

  React.useEffect(() => {
    logoutIfLoggedIn();
  }, []);

  return loading ? (
    <Loader open={true} />
  ) : (
    <StepContent>
      <StepLeftContainer>
        <Text as="h2">Welcome to Seedz</Text>
        <Text as="p" size={20} lineHeight={30}>
          <strong>Seedz </strong>
          is the best way to build your business locally and organically. Draw
          in new customers in your area, or others that are out of reach. Plant
          <strong> Seedz</strong>, grow your business.
        </Text>
        <StepLeftImageContent>
          <img src={Step1Image} alt="" />
        </StepLeftImageContent>
      </StepLeftContainer>
      <StepRightContainer>
        <Text as="h2">Create your account</Text>
        <Text as="p" size={20} lineHeight={30}>
          First, you'll want to register for an account so that you can access
          the <strong>Seedz</strong> management dashboard from anywhere!
        </Text>
        <CreateUserForm onSubmitSuccess={nextStep} />
      </StepRightContainer>
    </StepContent>
  );
};
