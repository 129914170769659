import * as React from 'react';
import { Formik, FormikProps } from 'formik';
import { FormGroup, InputGroup, Switch } from '@blueprintjs/core';
import * as yup from 'yup';
import styled from 'styled-components';
import { Button, Colors } from '@humancollective/uikit-web';

import { FormError } from './Fields';

import { Firebase } from '../../utilities';
import Loader from '../Loader';

interface CreateUserValues {
  email: string;
  password: string;
}
const initialValues: CreateUserValues = {
  email: '',
  password: '',
};

const validationSchema = yup.object().shape<CreateUserValues>({
  email: yup
    .string()
    .email('Invalid email')
    .required('Required'),
  password: yup
    .string()
    .min(6)
    .required('Required'),
});

const StyledForm = styled.form`
  .bp3-form-group {
    > label.bp3-label {
      color: ${Colors.primary};
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

interface CreateUserForm {
  onSubmitSuccess?: () => void;
}
const CreateUserForm: React.FunctionComponent<CreateUserForm> = ({
  onSubmitSuccess,
}) => {
  const [formError, setFormError] = React.useState<string | undefined>();
  const [passwordVisible, setPasswordVisible] = React.useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        setTimeout(async () => {
          setSubmitting(true);
          setFormError(undefined);
          try {
            // Register the user
            await Firebase.user.register(values.email, values.password);

            // If we have an onSubmitSuccess prop
            if (onSubmitSuccess) onSubmitSuccess();
          } catch (error) {
            setFormError(error.message);
          }
          setSubmitting(false);
        }, 1000);
      }}
      validationSchema={validationSchema}
    >
      {({
        isSubmitting,
        values,
        handleChange,
        handleSubmit,
        errors,
      }: FormikProps<CreateUserValues>) => (
        <StyledForm onSubmit={handleSubmit}>
          <Loader open={isSubmitting} />
          {formError && <FormError>{formError}</FormError>}
          <FormGroup label="Email" labelFor="email">
            {errors.email && <FormError>{errors.email}</FormError>}
            <InputGroup
              id="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              placeholder="john@example.com"
            />
          </FormGroup>
          <FormGroup label="Password" labelFor="password">
            {errors.password && <FormError>{errors.password}</FormError>}
            <InputGroup
              type={passwordVisible ? 'text' : 'password'}
              id="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              placeholder="•••••••"
            />
          </FormGroup>
          <Switch
            checked={passwordVisible}
            label="Show password"
            onChange={event => setPasswordVisible(event.target.checked)}
          />
          <Button type="submit" label="Register" disabled={isSubmitting} />
        </StyledForm>
      )}
    </Formik>
  );
};

export default CreateUserForm;
