import React from 'react';
import { Button, Text } from '@humancollective/uikit-web';
import { Link } from 'gatsby';

import AppStoreButton from '../Button/AppStore';
import Step4Image from '../../assets/images/register/step4.png';
import { RegisterStepComponentProps } from './';
import {
  StepContent,
  StepLeftContainer,
  StepLeftImageContent,
  StepRightContainer,
} from './display';

export const CompleteRegisterStep: React.FunctionComponent<
  RegisterStepComponentProps
> = () => (
  <StepContent>
    <StepLeftContainer>
      <StepLeftImageContent large={true}>
        <img src={Step4Image} alt="" />
        <Text as="h2">Available on the App Store and Google Play</Text>
        <div>
          <AppStoreButton platform="ios" />
          <AppStoreButton platform="android" />
        </div>
      </StepLeftImageContent>
    </StepLeftContainer>
    <StepRightContainer>
      <Text as="h2">You're all set!</Text>
      <Text as="p" size={20} lineHeight={30}>
        You've finished creating your account. You can now start placing
        campaigns and locations!
      </Text>
      <Link to="/map">
        <Button label="Start Planting" />
      </Link>
    </StepRightContainer>
  </StepContent>
);
