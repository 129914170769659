import styled, { css } from 'styled-components';
import { Colors as KitColors } from '@humancollective/uikit-web';

export const StepContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;

const BaseStepContentStyles = css`
  border-radius: 7px;
  padding: 40px;
  > h2 {
    margin-bottom: 20px;
  }

  > p {
    margin-bottom: 20px;
  }
`;

export const StepLeftContainer = styled.div`
  ${BaseStepContentStyles};
  background-color: ${KitColors.backgroundSecondary};
  min-width: 500px;
  min-height: 400px;
  max-height: 500px;
  position: relative;
`;

// The large prop tells us we have a large image that goes out of bounds
export const StepLeftImageContent = styled.div<{ large?: boolean }>`
  position: absolute;
  /* Take up the full width, minus the 40px padding on both sides*/
  width: calc(100% - 80px);
  max-height: 500px;

  > img {
    width: 100%;
    height: auto;
  }

  ${props =>
    props.large &&
    `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > img {
      width: 55%;
      height: 450px;
    }

    > div {
      margin-top: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 65%;
    }
  `};

  > h2 {
    margin-top: 15px;
    text-align: center;
  }
`;

export const StepRightContainer = styled.div`
  ${BaseStepContentStyles};
  padding-top: 0;
  margin-left: 40px;
  min-width: 550px;
`;
