import * as React from 'react';
import styled from 'styled-components';

import {
  Colors as KitColors,
  StepProgress,
  Text,
} from '@humancollective/uikit-web';

import {
  CompleteRegisterStep,
  CreateAccountRegisterStep,
  CreateBusinessRegisterStep,
  SocialAccountsRegisterStep,
} from '../components/RegisterSteps';

const StyledRegisterPage = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1120px;
  margin: auto auto;
  padding: 40px;
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;

  > div {
    min-width: 550px;
    height: 100%;
    /* Pad the left by 60 because 40 + 20 (from the margin-left on StepRightContainer) */
    padding-left: 60px;

    > p {
      margin-bottom: 10px;
    }
  }
`;

const Register: React.FunctionComponent = ({}) => {
  const [stepIndex, setStepIndex] = React.useState<number>(0);
  const nextStep = () => setStepIndex(stepIndex + 1);

  const stepComponents = [
    CreateAccountRegisterStep,
    CreateBusinessRegisterStep,
    SocialAccountsRegisterStep,
    CompleteRegisterStep,
  ];

  const renderCurrentStep = (currenStepIndex: number) => {
    const CurrentStepComponent = stepComponents[currenStepIndex];
    return <CurrentStepComponent nextStep={nextStep} />;
  };

  // Show the step if we're not determining the current step (aka the loader being open)
  return (
    <StyledRegisterPage>
      <ProgressContainer>
        <div>
          <Text as="p" textColor={KitColors.primary}>
            Step{' '}
            <strong>
              {stepIndex + 1} / {stepComponents.length}
            </strong>
          </Text>
          <StepProgress
            step={stepIndex + 1}
            steps={stepComponents.length}
            inactiveColor={KitColors.backgroundSecondary}
          />
        </div>
      </ProgressContainer>
      {renderCurrentStep(stepIndex)}
    </StyledRegisterPage>
  );
};

export default Register;
