import * as React from 'react';
import styled from 'styled-components';
import { imagePlayStore, imageAppStore } from '../../images/home';

const Container = styled.a<{ platformImage: any }>`
  width: 120px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  transition: opacity 0.5s;

  &:hover {
    opacity: 0.85;
  }
  background-image: url(${props => props.platformImage});
`;

type PlatformType = 'android' | 'ios';

const PLATFORMS: { [key in PlatformType]: { image: any; url: string } } = {
  ios: {
    image: imageAppStore,
    url: 'https://apps.apple.com/us/app/seedz/id1438681617',
  },
  android: {
    image: imagePlayStore,
    url: 'https://play.google.com/store/apps/details?id=com.seedz',
  },
};

export interface AppStoreButton {
  platform: PlatformType;
}

const AppStoreButton: React.FunctionComponent<AppStoreButton> = ({
  platform,
}) => {
  const platformInfo = PLATFORMS[platform];

  return (
    <Container href={platformInfo.url} platformImage={platformInfo.image} />
  );
};

export default AppStoreButton;
