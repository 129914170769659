import React from 'react';
import { Text } from '@humancollective/uikit-web';

import { BusinessSocialForm } from '../Forms/Business';
import Step3Image from '../../assets/images/register/step3.png';
import { RegisterStepComponentProps } from './';
import {
  StepContent,
  StepLeftContainer,
  StepLeftImageContent,
  StepRightContainer,
} from './display';
import { ProfileContext } from '../../contexts';
import Loader from '../Loader';

export const SocialAccountsRegisterStep: React.FunctionComponent<
  RegisterStepComponentProps
> = ({ nextStep }) => {
  const profile = React.useContext(ProfileContext);
  return !profile || !profile.business ? (
    <Loader open={true} />
  ) : (
    <StepContent key="step-3">
      <StepLeftContainer>
        <Text as="h2">Create your business.</Text>
        <Text as="p" size={20} lineHeight={30}>
          Your contact info and social links will be saved and linked to your
          location content with the ability to edit at anytime.
        </Text>
        <StepLeftImageContent>
          <img src={Step3Image} alt="" />
        </StepLeftImageContent>
      </StepLeftContainer>
      <StepRightContainer>
        <Text as="h2">Contact info and links.</Text>
        <Text as="p" size={20} lineHeight={30}>
          Link your email, phone number, and social media accounts that will
          automatically be integrated with your locations and campaigns.
        </Text>
        {console.log({ business: profile.business })}
        <BusinessSocialForm
          businessId={profile.business}
          onSuccess={nextStep}
          onSkipPress={nextStep}
        />
      </StepRightContainer>
    </StepContent>
  );
};
