import React from 'react';
import { Text } from '@humancollective/uikit-web';

import { BusinessDetailsForm } from '../Forms/Business';
import Step2Image from '../../assets/images/register/step2.png';
import { RegisterStepComponentProps } from './';
import {
  StepContent,
  StepLeftContainer,
  StepLeftImageContent,
  StepRightContainer,
} from './display';
import { Firebase } from '../../utilities';

export const CreateBusinessRegisterStep: React.FunctionComponent<
  RegisterStepComponentProps
> = ({ nextStep }) => (
  <StepContent>
    <StepLeftContainer>
      <Text as="h2">Create your business.</Text>
      <Text as="p" size={20} lineHeight={30}>
        Your profile sets you up to plant <strong>Seedz</strong> in locations
        across your city and helps promote your business.
      </Text>
      <StepLeftImageContent>
        <img src={Step2Image} alt="" />
      </StepLeftImageContent>
    </StepLeftContainer>
    <StepRightContainer>
      <Text as="h2">Fill in your details.</Text>
      <Text as="p" size={20} lineHeight={30}>
        We need to know more about you and your business.
      </Text>
      <BusinessDetailsForm
        onSuccess={async ({ id }) => {
          await Firebase.user.updateCurrentUser({ business: id });
          nextStep();
        }}
      />
    </StepRightContainer>
  </StepContent>
);
